import {useEffect, useState} from 'react';
import Loader from '../General/Loader';
import {ImageGallery} from '../Photography/ImageGallery';
import s3 from './../../aws-config';
import {PhotoGenres} from "../Photography/PhotoGenres";

export function Photography() {
    const [newCategory, setCategory] = useState<string>("Open Kaart");
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const bucketName = 'lva-creative';
        const folderPrefix = `images/${newCategory}`;

        // Use the listObjects operation to fetch object keys within the specified prefix
        s3.listObjects({Bucket: bucketName, Prefix: folderPrefix}, (err, data) => {
            if (err) {
                console.error('Error fetching object keys from S3:', err);
                setLoading(false);
            } else {
                const objectKeys = data.Contents?.map((item) => item.Key || '') || [];

                // Generate pre-signed URLs for each object key
                const promises = objectKeys.map((key) => {
                    return generatePresignedUrl(bucketName, key);
                });

                Promise.all(promises)
                    .then((urls) => {
                        const urlsExcludingFirst = urls.slice(1);
                        setImageUrls(urlsExcludingFirst);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error generating pre-signed URLs:', error);
                        setLoading(false);
                    });
            }
        });
    }, [newCategory]);

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <PhotoGenres category={newCategory} setCategory={setCategory}/>
                    <ImageGallery images={imageUrls}/>
                </>
            )}
        </>
    );
}

export function generatePresignedUrl(bucketName: string, objectKey: string) {
    const params = {
        Bucket: bucketName,
        Key: objectKey,
        Expires: 3600, 
    };

    return s3.getSignedUrl('getObject', params);
}
