import "./../../css/welcome.css"

export function Welcome() {
    return (
        <>
            <section>
                <h2 className="welcome-title">Welcome,</h2>
                <h2 className="welcome-subtitle">Let’s get creative together.</h2>
            </section>
        </>
    )
}
