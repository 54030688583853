import { Fragment, useEffect, useState } from 'react';
import FsLightbox from 'fslightbox-react';
import '../../css/imageGallery.css';
import Loader from "../General/Loader";

export interface ImageProps {
    images: string[];
}

export function ImageGallery(props: ImageProps) {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [currentImages, setCurrentImages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true); 
	const [selectedPage, setSelectedPage] = useState(1);


    const closeLightbox = () => {
        setLightboxOpen(false);
        setLightboxIndex(0);
    };

    const imagesPerPage = 18;

    useEffect(() => {
        const indexOfLastImage = currentPage * imagesPerPage;
        const indexOfFirstImage = indexOfLastImage - imagesPerPage;
        const currentImagesSlice = props.images.slice(indexOfFirstImage, indexOfLastImage);

        // Simulate loading delay
        setTimeout(() => {
            setCurrentImages(currentImagesSlice);
            setIsLoading(false); // Set loading to false when images are loaded
        }, 1000); // Adjust the delay time as needed
    }, [currentPage, props.images]);

	const paginate = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		setIsLoading(true);
		setSelectedPage(pageNumber); // Update the selected page
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	

    const openFullScreenImage = (imageName: string) => {
        setSelectedImage(imageName);
    };

    const closeFullScreenImage = () => {
        setSelectedImage(null);
    };

    return (
        <>
            {isLoading ? ( // Show loading screen while images are loading
                <Loader/>
            ) : (
                <div className="gallery">
                    {currentImages.map((imageName: string, index: number) => (
                        <div className="image" key={index}>
                            <img
                                key={index}
                                src={imageName}
                                alt={imageName}
                                data-src={imageName}
                                onClick={() => openFullScreenImage(imageName)}
                                loading="lazy"
                            />
                        </div>
                    ))}
                </div>
            )}

            {lightboxOpen && (
                <FsLightbox
                    toggler={lightboxOpen}
                    sources={Object.values(props.images)}
                    sourceIndex={lightboxIndex}
                    onClose={closeLightbox}
                />
            )}

            {selectedImage && (
                <div className="fullscreen-overlay" onClick={closeFullScreenImage}>
                    <img className="fullscreen-image" src={selectedImage} alt={selectedImage} />
                </div>
            )}

		<div className="pagination">
    		{Object.keys(props.images).length > imagesPerPage && Array.from(
        	{ length: Math.ceil(Object.keys(props.images).length / imagesPerPage) },
        	(_, i) => (
            <Fragment key={i}>
                <button
                    className={`paginateBtn ${selectedPage === i + 1 ? 'selected' : ''}`}
                    onClick={() => paginate(i + 1)}
                >
                    {i + 1}
                </button>
                {(i + 1) % 5 === 0 && <br />}
            </Fragment>
        )
    	)}
</div>
        </>
    );
}
