import React, {Dispatch, Fragment, SetStateAction, useEffect, useState} from 'react';
import {ref, listAll} from "firebase/storage";
import {storage} from "../../firebase";
import "./../../css/imageGallery.css"

interface photoGenresProps {
	category:string
    setCategory: Dispatch<SetStateAction<string>>
}

export function PhotoGenres(props: photoGenresProps) {
    const [subfolders, setSubfolders] = useState<string[]>([]);

    useEffect(() => {
        const storageRef = ref(storage, 'images');
        listAll(storageRef)
            .then((result) => {
                const subfolderNames = result.prefixes.map((prefix) =>
                    prefix.name.replace('images/', '')
                );
                setSubfolders(subfolderNames);
            })
            .catch((error) => {
                console.error('Error fetching subfolders: ', error);
            });
    }, []);

    function changeImages(subfolder: string) {
        props.setCategory(subfolder)
    }

    return (
        <>
            <h2 className="subTitle">Photography</h2>
            <div className="photoGenresBlock">
                {subfolders.map((subfolder, i) => (
                    subfolder === "Banner" ? null : (
                        <Fragment key={i}>
                            <button
                                key={subfolder}
								className={`photoGenresBtn ${props.category === subfolder ? 'selected' : ''}`}
                                style={{
                                    width: "325px",

                                }}
                                onClick={() => changeImages(subfolder)}
                            >
                                {subfolder}
                            </button>
                        </Fragment>
                    )
                ))}
            </div>

        </>
    );
}
