import {Banner} from "../Home/Banner";
import {Welcome} from "../Home/Welcome";

export function Home() {
    return (
        <>
            <Banner/>
            <Welcome/>
        </>
    )
}
